<template>
  <v-card
    dark
    height="140"
    outlined
    color="#555"
    class="expande-horizontal pa-3"
    style="flex-wrap: wrap; border-radius: 12px;"
  >
    <div class="expande-horizontal column" style="flex-wrap: nowrap;">
      <span style="font-size: 8pt;" class="fonte mb-2 white--text"
        >Binário</span
      >
      <div class="expande-horizontal">
        <v-btn
          :loading="loading"
          @click="setBinarySideActive('left')"
          color="yellow"
          :dark="side !== 'left'"
          class="fonte fonte-bold"
          :class="{
            'black--text': side == 'left'
          }"
          :text="side !== 'left'"
          >Esquerdo</v-btn
        >
        <v-btn
          :loading="loading"
          @click="setBinarySideActive('right')"
          color="yellow"
          class="fonte fonte-bold"
          :class="{
            'black--text': side == 'right'
          }"
          :text="side !== 'right'"
          :dark="side !== 'right'"
          >Direito</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn @click="$router.push('/binario')" icon color="grey">
          <v-icon size="30" color="yellow"> mdi-chevron-right </v-icon>
        </v-btn>
      </div>
      <div class="expande-horizontal centraliza fonte-bold mt-2">
        Esquerda: {{ esquerda.indicated_name }} x Direita:
        {{ direita.indicated_name }}
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      side: "",
      loading: false,
      binary: {
        level_1: []
      }
    };
  },
  computed: {
    ...mapGetters(["getUnilevels", "getLoggedUser"]),
    esquerda() {
      let esquerda = this.binary.level_1.filter(i => i.side == "left");
      if (esquerda.length) {
        return esquerda[0];
      }
      return {
        sponsor_name: ""
      };
    },
    direita() {
      let direita = this.binary.level_1.filter(i => i.side == "right");
      if (direita.length) {
        return direita[0];
      }
      return {
        sponsor_name: ""
      };
    }
  },
  filters: {
    sum(value) {
      const soma =
        value.level_1.length +
        value.level_2.length +
        value.level_3.length +
        value.level_4.length +
        value.level_5.length +
        value.level_6.length;
      return soma;
    }
  },
  methods: {
    setBinarySideActive(side) {
      this.loading = true;
      this.$run(
        "user/set-side-binary",
        {
          side
        },
        "user"
      ).then(res => {
        this.loading = false;
        this.side = side;
      });
    },
    getBinarySideActive() {
      this.loading = true;
      this.$run("user/get-side-binary", null, "user").then(res => {
        this.loading = false;
        this.side = res.data.side;
      });
    },
    listBinaries() {
      this.$run("binary/list", null, "user").then(res => {
        this.binary = res.data;
      });
    }
  },
  mounted() {
    this.getBinarySideActive();
    this.listBinaries();
  }
};
</script>
