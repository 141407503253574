<template>
  <v-card
    dark
    outlined
    color="#555"
    class="expande-horizontal column pa-3"
    style="flex-wrap: wrap; border-radius: 12px;"
  >
    <span class="mb-3 fonte black--text" :style="`font-size: 12px;`">
      Graduação
    </span>
    <div
      :class="{
        'expande-horizontal centraliza': $vuetify.breakpoint.smAndDown
      }"
    >
      <div
        class="card-base"
        :class="{
          'personal-card': graduation == 'Personal',
          'gold-card force-black-text': graduation == 'Gold',
          'platinum-card force-black-text': graduation == 'Platinum',
          'black-card': graduation == 'Black',
          'black--text': [''].includes(graduation),
          'white--text': ['Personal', 'Gold', 'Platinum ', 'Black'].includes(
            graduation
          )
        }"
      >
        <div class="expande-horizontal" style="align-items: center;">
          <div class="expande-horizontal column">
            <span class="fonte-bold mb-0" :style="`font-size: 16px;`">{{
              graduation || "Sem classificação"
            }}</span>
            <span
              class="fonteMini"
              style="position: relative; margin-top: -8px;"
              >nível</span
            >
          </div>
          <div>
            <!-- <v-icon v-if="graduation" class="mr-0" color="white">mdi-crown</v-icon> -->
            <v-icon v-if="graduation" size="31" class="mr-0" color="white"
              >mdi-integrated-circuit-chip</v-icon
            >
            <span v-else class="fonte-bold white--text">SCN</span>
          </div>
        </div>
        <v-spacer></v-spacer>
        <div style="align-items: flex-end;" class="column expande-horizontal">
          <span class="fonte mt-3"> 0000 0000 0000 {{ getIdCard }}</span>
          <span class="fonte fonte-bold" style="font-size: 13pt;">{{
            firstName
          }}</span>
          <span style="font-size: 9pt;" class="fonteTitulo" v-if="!graduation"
            >{{ getLoggedUser.unilevel_level_1.length }} de 6 até o nível
            Personal</span
          >
          <span
            style="font-size: 9pt;"
            class="fonteTitulo"
            v-else-if="graduation == 'Personal'"
            >{{ getLoggedUser.unilevel_level_2.length }} de 36 até o nível
            Gold</span
          >
          <span
            style="font-size: 9pt;"
            class="fonteTitulo"
            v-else-if="graduation == 'Gold'"
            >{{ getLoggedUser.unilevel_level_3.length }} de 216 até o nível
            Platinum</span
          >
          <span
            style="font-size: 9pt;"
            class="fonteTitulo"
            v-else-if="graduation == 'Platinum'"
            >{{ getLoggedUser.unilevel_level_4.length }} de 1296 até o nível
            Black</span
          >
        </div>
        <!-- <div class="qr-code-scn"> -->
        <div class="logo-card-scn">
          <img
            class="img-logo"
            height="220"
            style="opacity: 0.4;"
            src="logo_mini.png"
            alt="logo scn"
          />
          <!-- <qrcode-vue render-as="svg" background="#fff" :value="getLoggedUser.cpf" :size="70" level="L" /> -->
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import QrcodeVue from "qrcode.vue";
export default {
  components: { QrcodeVue },
  computed: {
    ...mapGetters(["getLoggedUser", "getBalanceUnilevel"]),
    graduation() {
      let graduacao =
        this.getLoggedUser.unilevel_level_1.length == 6 ? "Personal" : "";
      if (
        graduacao == "Personal" &&
        this.getLoggedUser.unilevel_level_2.length == 36
      ) {
        graduacao = "Gold";
      }
      if (
        graduacao == "Gold" &&
        this.getLoggedUser.unilevel_level_3.length == 216
      ) {
        graduacao = "Platinum";
      }
      if (
        graduacao == "Platinum" &&
        this.getLoggedUser.unilevel_level_4.length == 1296
      ) {
        graduacao = "Black";
      }
      return graduacao;
    },
    firstName() {
      if (this.getLoggedUser) {
        return this.getLoggedUser.name.split(" ")[0];
      }
      return "";
    },
    getIdCard() {
      let id = "0000";
      let id_user = `${this.getLoggedUser.id_user}`;

      if (id_user.length == 1) {
        id = "000" + this.getLoggedUser.id_user;
      }

      if (id_user.length == 2) {
        id = "00" + this.getLoggedUser.id_user;
      }

      if (id_user.length == 3) {
        id = "0" + this.getLoggedUser.id_user;
      }
      if (id_user.length >= 4) {
        id = this.getLoggedUser.id_user;
      }

      return id;
    }
  }
};
</script>

<style>
.personal-card {
  background: #795548; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #795548,
    #533b33
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #795548,
    #4f382f
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  animation: pulse-personal 2s infinite alternate;
}
.gold-card {
  background: #ffb347; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ffcc33,
    #ffb347
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ffcc33,
    #ffb347
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.platinum-card {
  background: #000428; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #004e92,
    #000428
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #004e92,
    #000428
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.black-card {
  background: #000000; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #383839,
    #000000
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #383839,
    #000000
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
@keyframes pulse-personal {
  0% {
    box-shadow: 0 0 5px 0 rgba(255, 255, 255, 0.587);
  }
  100% {
    box-shadow: 0 0 10px 0 rgba(236, 237, 238, 0.5); /* Sombra final mais forte e maior */
  }
}
.card-base {
  display: flex;
  min-width: 320px;
  max-width: 320px;
  min-height: 202px;
  max-height: 202px;
  border-radius: 6px;
  /* box-shadow: 0 0 20px 0 rgba(253, 253, 253, 0.8); */
  flex-direction: column;
  padding: 12px;
  position: relative;
}
.qr-code-scn {
  position: absolute;
  bottom: 4px;
  left: 10;
  border-radius: 12px;
  padding: 6px;
  padding-bottom: 0px;
  background: #fff;
}
.logo-card-scn {
  position: absolute;
  left: 14%;
  margin-top: -23px;
  opacity: 0.3;
}
.force-black-text {
  color: #555 !important;
}
</style>
