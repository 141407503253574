<template>
  <v-card
    dark
    height="140"
    color="#555"
    class="expande-horizontal pa-3 card-balance-style"
    style="flex-wrap: wrap; border-radius: 12px;"
  >
    <div class="expande-horizontal column" style="flex-wrap: nowrap;">
      <span style="font-size: 8pt;" class="fonte mb-2 white--text"
        >Saldo PL</span
      >
      <div class="expande-horizontal">
        <span class="fonte-bold white--text" :style="`font-size: 26px;`">{{
          $helper.formataSaldo(getBalancePL.balance)
        }}</span>
        <v-spacer></v-spacer>
        <v-btn @click="$router.push('/transactions/pl')" icon color="grey">
          <v-icon size="30" color="white"> mdi-chevron-right </v-icon>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
    </div>

    <div class="expande-horizontal mt-3">
      <v-btn @click="$router.push('/saque/pl')" color="yellow" small>
        <span class="fonte-bold black--text">Acumulado geral:</span>
        <span class="black--text ml-1"> {{ $helper.formataSaldo(getBalancePL.acumulated || 0) }} </span>
      </v-btn>
      <v-btn @click="$router.push('/saque/pl')" color="yellow" small class="ml-2">
        <span class="fonte-bold black--text">Saque</span>
        <span style="font-size: 20pt;"></span>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getBalancePL"])
  }
};
</script>
