<template>
  <v-card
    dark
    color="#555"
    height="120"
    class="expande-horizontal pa-3"
    style="flex-wrap: wrap;"
  >
    <div
      class="expande-horizontal"
      style="flex-wrap: nowrap; align-items: center;"
    >
      <span :style="`font-size: 36px; font-weight: bold; color: ${$theme.primary}`"
        >{{ getBalanceToken.balance }}</span
      >
      <v-spacer></v-spacer>
      <!-- <v-icon>mdi-cash-multiple</v-icon> -->
    </div>

    <div
      class="expande-horizontal"
      style="flex-wrap: nowrap; align-items: center;"
    >
      <div class="coluna">
        <!-- <span style="font-size: 8pt;" class="fonte"> Total em Vendas </span> -->
        <span class="fonteTitulo grey--text">MEUS TOKENS</span>
      </div>
      <v-spacer></v-spacer>
      <div>
        <v-avatar color="#555;">
          <v-img :src="$theme.token_img"></v-img>
        </v-avatar>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters([
            'getBalanceToken'
        ])
    }
}
</script>
