<template>
  <v-card
    @click="() => {}"
    dark
    height="120"
    color="#555"
    class="expande-horizontal pa-3"
    style="flex-wrap: wrap;"
  >
    <div
      class="expande-horizontal"
      style="flex-wrap: nowrap; align-items: center;"
    >
      <span :style="`font-size: 36px; font-weight: bold; color: ${$theme.primary}`"
        > {{ getNextMonthlyPayment }} </span
      >
      <v-spacer></v-spacer>
      <!-- <v-icon>mdi-cash-multiple</v-icon> -->
    </div>

    <div
      class="expande-horizontal"
      style="flex-wrap: nowrap; align-items: center;"
    >
      <div class="coluna">
        <!-- <span style="font-size: 8pt;" class="fonte"> Total em Vendas </span> -->
        <span class="fonteTitulo grey--text">PRÓXIMA MENSALIDADE</span>
      </div>
      <v-spacer></v-spacer>
      <div>
        <v-switch v-model="getLoggedUser.automatic_monthly_payment" @change="updateAutimaticMonthlyPayment($event)" color="green" label="Pagar Automaticamente"></v-switch>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters([
      "getNextMonthlyPayment",
      "getLoggedUser"
    ])
  },
  methods: {
    ...mapActions([
      "listMonthlyPayment",
      "updateAutimaticMonthlyPayment"
    ])
  },
  created() {
    this.listMonthlyPayment()
  }
}
</script>
