<template>
  <v-card
    dark
    height="140"
    class="expande-horizontal pa-3 card-balance-style"
    style="flex-wrap: wrap; border-radius: 12px;"
  >
    <div class="expande-horizontal column" style="flex-wrap: nowrap;">
      <span style="font-size: 8pt;" class="fonte mb-2 white--text"
        >Minha Rede</span
      >
      <div class="expande-horizontal">
        <span class="fonte-bold white--text" :style="`font-size: 26px;`"
          >{{ getUnilevels | sum }} Pessoas na rede</span
        >
        <v-spacer></v-spacer>
        <v-btn @click="$router.push('/unilevel')" icon color="grey">
          <v-icon size="30" color="yellow"> mdi-chevron-right </v-icon>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
    </div>

    <div class="expande-horizontal wrap mt-1">
      <v-btn depressed rounded small color="yellow">
        <span class="text-capitalize black--text"
          >{{ getUnilevels.level_1.length }} Diretos</span
        >
      </v-btn>
      <v-btn depressed rounded small color="yellow" class="ma-1 mt-0">
        <span class="text-lowercase" style="color: grey;"
          ><span class="fonte-bold" style="font-size: 10pt; color: #555;"
            >{{ getUnilevels.level_2.length }}
          </span>
          no 2º
        </span>
      </v-btn>
      <v-btn depressed rounded small color="yellow" class="ma-1 mt-0">
        <span class="text-lowercase" style="color: grey;"
          ><span class="fonte-bold" style="font-size: 10pt; color: #555;"
            >{{ getUnilevels.level_3.length }}
          </span>
          no 3º
        </span>
      </v-btn>
      <v-btn depressed rounded color="yellow" small class="ma-1 mt-0">
        <span class="text-lowercase" style="color: grey;"
          ><span class="fonte-bold" style="font-size: 10pt; color: #555;"
            >{{ getUnilevels.level_4.length }}
          </span>
          no 4º
        </span>
      </v-btn>
      <v-btn depressed rounded color="yellow" small class="ma-1 mt-0">
        <span class="text-lowercase" style="color: grey;"
          ><span class="fonte-bold" style="font-size: 10pt; color: #555;"
            >{{ getUnilevels.level_5.length }}
          </span>
          no 5º
        </span>
      </v-btn>
      <v-btn depressed rounded color="yellow" small class="ma-1 mt-0">
        <span class="text-lowercase" style="color: grey;"
          ><span class="fonte-bold" style="font-size: 10pt; color: #555;"
            >{{ getUnilevels.level_6.length }}
          </span>
          no 6º
        </span>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getUnilevels"])
  },
  filters: {
    sum(value) {
      const soma =
        value.level_1.length +
        value.level_2.length +
        value.level_3.length +
        value.level_4.length +
        value.level_5.length +
        value.level_6.length;
      return soma;
    }
  }
};
</script>
