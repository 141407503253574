<template>
  <v-card
    dark
    height="250"
    hover
    :color="`${getLoggedUser.subscription ? '#555' : '#555'}`"
    class="expande-horizontal card-style pa-3"
    style="flex-wrap: wrap; border-radius: 12px;"
  >
    <div class="expande-horizontal column" style="flex-wrap: nowrap;">
      <span style="font-size: 8pt;" class="fonte mb-2 white--text"
        >Minha Licença</span
      >
      <div class="expande-horizontal">
        <span
          v-if="!getLoggedUser.career_active"
          class="fonte-bold white--text"
          :style="`font-size: 26px;`"
        >
          Adquira uma licença para começar!
        </span>
        <span
          v-if="getLoggedUser.career_active"
          class="fonte-bold white--text"
          :style="`font-size: 26px;`"
        >
          {{ getLoggedUser.career_info.description }}
        </span>
        <v-spacer></v-spacer>
        <v-btn v-if="getLoggedUser.career_active" icon color="grey">
          <span style="font-size: 30pt;">
            🎖
          </span>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
    </div>

    <div class="expande-horizontal mt-3">
      <!-- <v-btn depressed rounded outlined small light color="#555">
        <span class="text-capitalize"> 
          <v-icon size="19">mdi-piggy-bank-outline</v-icon>
          {{ $helper.formataSaldo(getBalanceUnilevel.balance_locked) }}
        </span>
      </v-btn> -->
      <v-btn
        @click="$router.push('/careers')"
        class=""
        depressed
        rounded
        small
        dark
        v-if="!getLoggedUser.career_active"
      >
        <span class="fonte-bold">Ir para licenças</span>
        <v-icon size="19">mdi-crown</v-icon>
      </v-btn>
      <div v-else class="expande-horizontal white--text column">
        <span class="fonteMini">Limite de aporte:</span>
        <span> $ {{ getLoggedUser.career_info.price * 20 }}</span>
      </div>

      <v-btn
        @click="
          openPurchase({ ...getInvestments[0], purchase_type: 'investment' })
        "
        large
        color="yellow"
        v-if="getLoggedUser.career_active"
      >
        <span class="fonte-bold black--text"> + {{ getInvestments[0].title }} </span>
        <span style="font-size: 20pt" class="fonte-bold black--text">  </span>
        <!-- <span style="font-size: 20pt" class="fonte-bold black--text"> 🤑 </span> -->
      </v-btn>
      <!-- <v-btn class="ml-2" depressed rounded outlined small light color="#555">
        <v-icon size="19">mdi-wallet-plus-outline</v-icon>
        <span class="text-capitalize">Depósito</span>
      </v-btn> -->
      <!-- <v-btn class="ml-2" depressed rounded outlined small light color="#555">
        <v-icon size="19">mdi-wallet-plus-outline</v-icon>
        <span class="text-capitalize">Receber</span>
      </v-btn> -->
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getLoggedUser", "getInvestments"])
  },
  methods: {
    ...mapActions(["openPurchase"])
  }
};
</script>
