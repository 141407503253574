<template>
  <layout :title="$route.name" :options="options" :toolbar_off="false">
    <div class="expande-horizontal wrap" style="z-index: 9999;">
      <span
        v-if="showWelcome"
        :class="{
          'white--text': dark,
          'black--text': !dark
        }"
        style="font-size: 22pt;"
        class="message fonte-bold animate__animated animate__fadeInUp"
        >Bem vindo(a)!</span
      >
      <v-flex v-if="false" xs12 class="pa-3 pb-0">
        <div class="expande-horizontal centraliza">
          <!-- <img
            class="img-logo"
            height="40"
            src="logo.jpeg"
            style="border-radius: 6px;"
          /> -->

          <v-spacer></v-spacer>
          <!-- <div class="expande-horizontal column title-item">
            <span style="font-size: 16pt;" class="fonte-bold">SCN</span>
            <span class="fonte grey--text">Bem vindo(a)</span>
          </div> -->
          <div>
            <!-- <v-avatar
              @click="$router.push('/logged-user')"
              size="31"
              style="cursor: pointer;"
              color="#555"
              icon
            >
              <v-icon size="21">mdi-account</v-icon>
            </v-avatar> -->
            <v-menu left offset-y rounded bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon v-if="!profile_image" color="blue"
                    >mdi-account-circle-outline</v-icon
                  >
                  <v-avatar v-else>
                    <v-img :src="profile_image"></v-img>
                  </v-avatar>
                </v-btn>
              </template>

              <v-list style="min-width: 300px;" class="fonte px-0 mx-0" dense>
                <v-list-item class="mx-0 px-2" to="/logged-user">
                  <v-avatar
                    v-if="!profile_image"
                    size="35"
                    color="#555"
                    class="mr-2"
                  >
                    <v-icon size="19">mdi-account-circle-outline</v-icon>
                  </v-avatar>
                  <v-avatar class="mr-2" size="35" v-else>
                    <v-img :src="profile_image"></v-img>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      style="font-size: 11pt;"
                      class="fonte-bold"
                      >Meu perfil</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      getLoggedUser.name
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="mx-0 px-2" to="/config">
                  <v-avatar size="35" color="#555" class="mr-2">
                    <v-icon size="19">mdi-cog-outline</v-icon>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      style="font-size: 11pt;"
                      class="fonte-bold"
                      >Configurações</v-list-item-title
                    >
                    <v-list-item-subtitle
                      >Foto de perfil e senha.</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="logout" class="mx-0 px-2">
                  <v-avatar size="35" color="#555" class="mr-2">
                    <v-icon size="19">mdi-run</v-icon>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      style="font-size: 11pt;"
                      class="fonte-bold"
                      >Sair</v-list-item-title
                    >
                    <v-list-item-subtitle
                      >desconecta a sua conta</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <!-- <span class="fonte-bold text-uppercase">
              {{  firstName  }}
            </span> -->
          </div>
        </div>
      </v-flex>
      <!-- <v-flex xs12 class="pa-6 pb-0">
        <v-alert type="success">
          Mensagem fixa
        </v-alert>
      </v-flex> -->

      <!-- <v-flex
        xs12
        md12
        :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-6 mb-6'"
      >
        <ChartSaldo />
      </v-flex> -->
      <!-- <v-flex xs12 md6 :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-6' ">
                <ChartSaldo />
            </v-flex> -->
      <!-- <v-flex xs12>
        <div
          :class="{ centraliza: $vuetify.breakpoint.smAndDown }"
          class="expande-horizontal pa-3"
        >
          <Graduacao />
        </div>
      </v-flex> -->
      <v-flex class="animate__animated animate__fadeIn animate__delay-2s" xs12>
        <div class="expande-horizontal wrap pa-3 px-3">
          <v-flex v-if="getLoggedUser.career_active" xs12>
            <h3 class="fonte-bold white--text pa-2">🍀 Resumo</h3>
          </v-flex>
          <v-flex xs12 class="">
            <div class="expande-horizontal wrap">
              <v-flex
                :class="{
                  'py-2 mb-3': !$vuetify.breakpoint.lgAndUp,
                  'pa-0': $vuetify.breakpoint.lgAndUp
                }"
                xs12
                md3
                v-if="getLoggedUser.career_active" 
                style="max-height: 250px;"
              >
                <v-card
                  v-if="getLoggedUser"
                  style="border-radius: 12px;"
                  height="250"
                  class="pa-2 card-style column expande-horizontal"
                >
                  <h3 class="white--text fonte-bold ma-2">
                    🪙 Saldos
                  </h3>
                  <Chart
                    :dark="true"
                    v-if="showCharts"
                    type="polarArea"
                    ref="chartValue"
                    :backgroundColor="[
                      'rgb(255, 205, 86, 0.8)',
                      'rgb(255, 99, 132)',
                      'rgb(75, 192, 192)',
                      'rgb(201, 203, 207)'
                    ]"
                    description="Saldo"
                    :labels="['Unilevel', 'Rentabilidade', 'PL']"
                    :dataChart="balances"
                    :id="'chart-1'"
                    :height="190"
                  />
                </v-card>
              </v-flex>
              <v-flex
                :class="{
                  'px-2': $vuetify.breakpoint.lgAndUp,
                  'py-2': !$vuetify.breakpoint.lgAndUp
                }"
                xs12
                class="pa-0"
                md6
                v-if="getLoggedUser.career_active"
              >
                <v-card
                  style="border-radius: 12px; min-height: 250px; max-height: 250px;"
                  :color="dark ? '#555' : '#555'"
                  :dark="dark"
                  class="pa-2 pl-0 pt-0 card-style expande-horizontal column animate__animated animate__fadeIn"
                >
                  <h3 class="fonte-bold ma-2 white--text">
                    📈 Rentabilidade - últimos 30 dias
                  </h3>
                  <Chart
                    :dark="false"
                    type="line"
                    v-if="showCharts"
                    ref="chartSales"
                    backgroundColor="rgb(255, 255, 255, 0.5)"
                    description="Rentabilidade"
                    :labels="salesLast30Days.days"
                    :dataChart="salesLast30Days.valuesForDays"
                    :id="'chart-2'"
                    :height="200"
                  />
                </v-card>
              </v-flex>
              <v-flex
                :class="{
                  'py-2': !$vuetify.breakpoint.lgAndUp
                }"
                xs12
                md3
                class="pa-0"
              >
                <Subscription />
              </v-flex>
            </div>
          </v-flex>
          <!-- <div
            @click="$router.push('/pagar/unilevel')"
            class="centraliza column mr-4"
            style="display: flex; width: 60px;"
          >
            <div
              class="centraliza"
              style="border-radius: 12px; background-color: #555; display: flex; width: 60px; height: 60px;"
            >
              <v-icon size="30">mdi-account-key-outline</v-icon>
            </div>
            <span class="fonte-bold black--text">Autenticar</span>
          </div> -->
          <!-- <div
            @click="$router.push('/transferencia/unilevel')"
            class="centraliza column mr-4"
            style="display: flex; width: 60px;"
          >
            <div
              class="centraliza"
              style="border-radius: 12px; background-color: #555; display: flex; width: 60px; height: 60px;"
            >
              <v-icon size="30">mdi-cash-fast</v-icon>
            </div>
            <span class="fonte-bold black--text">Transferência</span>
          </div> -->
          <!-- <div
            @click="$router.push('/transactions')"
            class="centraliza column mr-4"
            style="display: flex; width: 60px;"
          >
            <div
              class="centraliza"
              style="border-radius: 12px; background-color: #555; display: flex; width: 60px; height: 60px;"
            >
              <v-icon color="white" size="30">mdi-receipt-text-outline</v-icon>
            </div>
            <span class="fonte-bold black--text">Extratos</span>
          </div> -->
          <!-- <div
            @click="$router.push('/deposito/unilevel')"
            class="centraliza column mr-4"
            style="display: flex; width: 60px;"
          >
            <div
              class="centraliza"
              style="border-radius: 12px; background-color: #555; display: flex; width: 60px; height: 60px;"
            >
              <v-icon color="white" size="30">mdi-cash-plus</v-icon>
            </div>
            <span class="fonte-bold black--text">Depósito</span>
          </div> -->
          <!-- <div
            @click="$router.push('/careers')"
            class="centraliza column mr-4"
            style="display: flex; width: 60px;"
          >
            <div
              class="centraliza"
              style="border-radius: 12px; background-color: #555; display: flex; width: 60px; height: 60px;"
            >
              <v-icon color="white" size="30">mdi-account-tie-outline</v-icon>
            </div>
            <span class="fonte-bold black--text">Licenças</span>
          </div> -->
          <!-- <div
            @click="$router.push('/estabelecimentos')"
            class="centraliza column mr-1"
            style="display: flex; width: 60px;"
          >
            <div
              class="centraliza"
              style="border-radius: 12px; background-color: #555; display: flex; width: 60px; height: 60px;"
            >
              <v-icon size="30">mdi-storefront</v-icon>
            </div>
            <span class="fonte-bold black--text">Empresas</span>
          </div> -->
          <!-- <div @click="$router.push('/solicitar-pagamento/unilevel')" class="centraliza column mr-3" style="display: flex; width: 60px;">
            <div
              class="centraliza"
              style="border-radius: 12px; background-color: #555; display: flex; width: 60px; height: 60px;"
            >
              <v-icon size="30">mdi-cash-register</v-icon>
            </div>
            <span class="fonte-bold">Receber</span>
          </div> -->
          <!-- <div @click="$router.push('/estabelecimentos')" class="centraliza column" style="display: flex; width: 60px;">
            <div
              class="centraliza"
              style="border-radius: 12px; background-color: #555; display: flex; width: 60px; height: 60px;"
            >
              <v-icon size="30">mdi-storefront-outline</v-icon>
            </div>
            <span class="fonte-bold">Empresas</span>
          </div> -->
        </div>
      </v-flex>
      <v-flex
        v-if="getLoggedUser.career_active"
        class="animate__animated animate__fadeIn animate__delay-2s"
        xs12
      >
        <div class="expande-horizontal wrap pa-3 px-0">
          <v-flex xs12>
            <h3 class="fonte-bold white--text px-6">🪙 Meus saldos</h3>
          </v-flex>

          <v-flex
            v-if="show_unilevel"
            xs12
            md3
            :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'px-3'"
          >
            <SaldoPL />
          </v-flex>
          <v-flex
            v-if="show_unilevel"
            xs12
            md3
            :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'px-3'"
          >
            <SaldoDisponivel />
          </v-flex>

          <v-flex
            xs12
            md3
            :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'px-3'"
          >
            <SaldoRentabilidade />
          </v-flex>
          <v-flex
            xs12
            md3
            :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'px-3'"
          >
            <SaldoInvestimento />
          </v-flex>
        </div>
      </v-flex>
      <v-flex
        v-if="getLoggedUser.career_active"
        class="animate__animated animate__fadeIn animate__delay-2s"
        xs12
      >
        <div class="expande-horizontal wrap pa-3 px-0">
          <v-flex xs12>
            <h3 class="fonte-bold white--text px-6">🎓 Unilevel</h3>
          </v-flex>
          <v-flex
            xs12
            md4
            :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'px-3'"
          >
            <Link />
          </v-flex>
          <!-- <v-flex
            xs12
            md4
            :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'px-3'"
          >
            <Binario />
          </v-flex> -->
          <v-flex
            v-if="show_unilevel"
            xs12
            md4
            :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'px-3'"
          >
            <Unilevel />
          </v-flex>
        </div>
      </v-flex>

      <!-- <v-flex class="px-3" xs12>
        <h3 class="fonte-bold black--text px-3">Operações</h3>
      </v-flex> -->

      <v-flex
        class="pa-3"
        v-for="purchase in []"
        v-show="purchase.purchase_type == 'investment'"
        :key="purchase._id"
        xs12
        md3
      >
        <v-card dark style="border-radius: 12px;" color="#555">
          <v-flex xs12>
            <div class="expande-horizontal wrap column">
              <div class="expande-horizontal pa-2">
                <span class="fonte-bold black--text px-2">
                  <!-- {{ purchase.item.title }} -->
                  {{ purchase.item.description }}</span
                >
                <v-spacer></v-spacer>
                <v-btn
                  dark
                  class="elevation-0"
                  :color="getColorByPurchaseProgress(purchase)"
                  x-small
                >
                  <span class="fonte-bold">
                    {{
                      purchase.current_status === "active"
                        ? ""
                        : purchase.current_status
                    }}
                  </span>
                  <span class="fonte-bold" v-if="purchase.total_percent_gained">
                    {{ parseInt(purchase.total_percent_gained) }} de
                    {{ purchase.item.total_percent_gain }}%
                  </span>
                </v-btn>
              </div>
              <!-- <div class="pa-3 pb-1 pt-0 expande-horizontal">
                <v-icon class="mr-1" color="orange" size="11">mdi-star</v-icon>
                <span style="font-size: 9pt;;" class="fonte orange--text">
                  Dia de saque: {{ $moment(purchase.due_date).format("DD") }} +
                  3 dias subsequentes
                </span>
              </div> -->
              <div class="pa-3 pb-1 pt-0 centraliza expande-horizontal">
                <span
                  style="font-size: 18pt;"
                  class="my-4 fonte-bold black--text"
                >
                  Saldo: $
                  {{ $toLocaleString(purchase.withdraw_available_value || 0) }}
                </span>
              </div>
              <!-- <div class="pa-3 pb-1 pt-0 expande-horizontal">
                <span style="font-size: 9pt;;" class="fonte red--text">
                  Saques: $
                  {{ $toLocaleString(purchase.withdraw_available_fired || 0) }}
                </span>
              </div> -->
              <div class="expande-horizontal" style="padding: 3px;">
                <v-progress-linear
                  :color="getColorByPurchaseProgress(purchase)"
                  height="15"
                  style="border-radius: 56px;"
                  stream
                  :value="purchase.total_percent_gained / 2.4"
                  :buffer-value="purchase.total_percent_gained / 2.4"
                  striped
                >
                  <template v-slot:default="{ value }">
                    <span class="black--text fonte-bold"
                      >{{ parseInt(purchase.total_percent_gained) }} de
                      {{ purchase.item.total_percent_gain }}%</span
                    >
                  </template>
                </v-progress-linear>
              </div>
            </div>
          </v-flex>
        </v-card>
      </v-flex>
      <!-- <v-flex
        v-if="show_unilevel"
        xs12
        md6
        :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-3'"
      >
        <SaldoContabil />
      </v-flex> -->
      <!-- <v-flex
        v-if="show_token"
        xs12
        md4
        :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-6'"
      >
        <Tokens />
      </v-flex> -->
      <!-- <v-flex v-if="show_unilevel" xs12 md4 :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-6'">
        <PlanoDeCarreira />
      </v-flex> -->

      <!-- <v-flex xs12 md4 :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-6'">
        <Notificacoes />
      </v-flex> -->
      <!-- <v-flex
        v-if="getLoggedUser.primeira_compra"
        xs12
        md4
        :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-6'"
      > -->

      <!-- <v-flex
        v-if="show_unilevel"
        xs12
        md6
        :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-3'"
      >
        <QtdEmpresas />
      </v-flex> -->
      <!-- <v-flex
        v-if="show_unilevel"
        xs12
        md6
        :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-3'"
      >
        <LinkEmpresarial />
      </v-flex> -->
      <!-- <v-flex xs12 md4 :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-6'">
        <TotalGain />
      </v-flex> -->
      <!-- <v-flex v-if="show_unilevel" xs12 md4 :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-6'">
        <PlanExpiresIn />
      </v-flex> -->
      <!-- <v-dialog width="300" v-model="modal_unread">
        <v-flex xs12>
          <v-card class="expande-horizontal wrap">
            <v-flex class="pa-3" xs12>
              <div class="expande-horizontal centraliza">
                <v-icon color="green"> mdi-bell-ring </v-icon>
              </div>
            </v-flex>
            <v-flex xs12>
              <h3 class="fonte text-center pa-3">
                {{ total_unreads }} notificações não lidas
              </h3>
            </v-flex>
            <v-flex xs12>
              <div class="expande-horizontal fonte pa-3 centraliza wrap">
                <v-btn
                  :color="$theme.primary"
                  @click="$router.push('/notifications')"
                  >Ir para notificações</v-btn
                >
              </div>
            </v-flex>
          </v-card>
        </v-flex>
      </v-dialog> -->
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Subscription from "./components/Subscription";
import Saldo from "./components/Saldo";
import SaldoPL from "./components/SaldoPL";
import SaldoDisponivel from "./components/SaldoDisponivel";
import SaldoContabil from "./components/SaldoContabil";
import PlanoDeCarreira from "./components/PlanoDeCarreira";
import SaldoInvestimento from "./components/SaldoInvestimento.vue";
import SaldoRentabilidade from "./components/SaldoRentabilidade.vue";
import Tokens from "./components/Tokens";
import Unilevel from "./components/Unilevel";
import Binario from "./components/Binario";
import Notificacoes from "./components/Notificacoes";
// import ChartSaldo from "./components/ChartSaldo";
import Link from "./components/Link";
import PlanExpiresIn from "./components/PlanExpiresIn";
import Graduacao from "./components/Graduacao";
import TotalGain from "./components/TotalGain";
import LinkEmpresarial from "./components/LinkEmpresarial";
import QtdEmpresas from "./components/QtdEmpresas";
import Chart from "./components/Chart.vue";

export default {
  data() {
    return {
      dark: true,
      showWelcome: true,
      showCharts: false,
      // show_unilevel: process.env.VUE_APP_LOJA_UNILEVEL,
      show_unilevel: true,
      show_token: process.env.VUE_APP_LOJA_TOKEN,
      show_ativos: process.env.VUE_APP_LOJA_ATIVOS,
      modal_unread: false,
      total_unreads: 0
    };
  },
  components: {
    Graduacao,
    Saldo,
    SaldoDisponivel,
    SaldoContabil,
    PlanoDeCarreira,
    Tokens,
    Unilevel,
    Notificacoes,
    // ChartSaldo,
    Link,
    PlanExpiresIn,
    TotalGain,
    LinkEmpresarial,
    QtdEmpresas,
    Binario,
    Subscription,
    SaldoInvestimento,
    SaldoRentabilidade,
    Chart,
    SaldoPL
  },
  computed: {
    ...mapGetters([
      "getDashboard",
      "getLoggedUser",
      "getPurchases",
      "get_notifications",
      "getBalanceUnilevel",
      "getBalanceInvestment",
      "getBalancePL",
      "getInvestiments"
    ]),
    balances() {
      return [
        // 0,
        this.getBalanceUnilevel.balance || 0,
        this.getBalanceInvestment.balance || 0,
        this.getBalancePL.balance || 0
        // 0
      ];
    },
    salesLast30Days() {
      let sales = this.getBalanceInvestment.extract || [];
      // let limit = this.$moment().subtract(30, "days").format("YYYY-MM-DD");
      // let salesLast30Days = sales.filter(sale => {
      //   return this.$moment(sale.created_at).format("YYYY-MM-DD") >= limit;
      // })
      let days = [];
      let valuesForDays = [];
      for (let i = 30; i > 0; i--) {
        let day = this.$moment()
          .subtract(i, "days")
          .format("DD/MM");
        let salesForDay = sales.filter(
          sale => this.$moment(sale.created_at).format("DD/MM") === day
        );

        days.push(day);
        valuesForDays.push(salesForDay.length ? salesForDay[0].value : 0);
      }
      return {
        days,
        valuesForDays
      };
    },
    profile_image() {
      const user = JSON.parse(localStorage.getItem("user"));
      const img_profile = user ? user.profile_image : false;
      return user ? img_profile : this.getLoggedUser.profile_image || "";
    },
    firstName() {
      if (this.getLoggedUser) {
        return this.getLoggedUser.name.split(" ")[0];
      }
      return "";
    }
  },
  methods: {
    ...mapActions([
      "listBalanceInvestment",
      "listBalanceToken",
      "listBalanceUnilevel",
      "listBalancePL",
      "list_notifications",
      "listPurchases",
      "listUnilevels",
      "listInvestments",
      "openPurchase",
      "logout"
    ]),
    getColorByPurchaseProgress(purchase) {
      if (purchase.total_percent_gained === purchase.item.total_percent_gain) {
        return "orange";
      }

      if (
        purchase.total_percent_gained &&
        purchase.total_percent_gained < purchase.item.total_percent_gain
      ) {
        return "black";
      }

      if (purchase.total_percent_gained === 0) {
        return "red";
      }
    },
    verifyNotifications() {
      const notifications = this.get_notifications.docs;
      let total_unreads = 0;

      if (!sessionStorage.notification_showed) {
        notifications.map(notify => {
          console.log("ok");
          if (!notify.readed) {
            total_unreads += 1;
          }
        });
        sessionStorage.notification_showed = total_unreads;
        this.modal_unread = true;
        this.total_unreads = total_unreads;
      } else {
        this.total_unreads = sessionStorage.notification_showed;
      }
    }
  },
  created() {
    let quiet = true;
    this.listBalanceInvestment(quiet);
    this.listBalanceToken(quiet);
    this.listBalanceUnilevel(quiet);
    this.listBalancePL(quiet);
    this.listUnilevels(quiet);
    this.listPurchases(quiet);
    this.listInvestments(quiet);
    this.list_notifications(quiet);
    setInterval(() => {
      this.verifyNotifications();
    }, 3000);
  },
  mounted() {
    setTimeout(() => {
      this.showCharts = true;
    }, 3000);
    setTimeout(() => {
      this.showWelcome = false;
    }, 2300);
  }
};
</script>

<style>
.message {
  width: 150px;
  /* animation: digitando 0.2s ease; */
  white-space: nowrap;
  overflow: hidden;

  position: absolute;
  top: 50%;
  left: 45%;
  /* transform: translate(-50%, -50%); */
}
.card-graduation {
  background: #232526; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #414345,
    #232526
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #414345,
    #232526
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.img-logo {
  filter: invert(97%);
}
</style>
