<template>
  <v-card
    dark
    color="#555"
    height="120"
    class="expande-horizontal pa-3"
    style="flex-wrap: wrap;"
  >
    <div
      class="expande-horizontal"
      style="flex-wrap: nowrap; align-items: center;"
    >
      <span :style="`font-size: 36px; font-weight: bold; color: ${$theme.primary}`"
        >{{ getLoggedUser.career_info.description || 'inativo ' }}</span
      >
      <v-spacer></v-spacer>
      <!-- <v-icon>mdi-cash-multiple</v-icon> -->
    </div>

    <div
      class="expande-horizontal"
      style="flex-wrap: nowrap; align-items: center;"
    >
      <div class="coluna">
        <!-- <span style="font-size: 8pt;" class="fonte"> Total em Vendas </span> -->
        <span class="fonteTitulo grey--text">Meu Plano (expira em {{ $moment(getLoggedUser.career_expires_in).format('DD/MM/YYYY') }}) </span>
      </div>
      <v-spacer></v-spacer>
      <div>
        <v-icon :color="$theme.primary"> mdi-trending-up </v-icon>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters([
      "getLoggedUser"
    ])
  }
}
</script>
