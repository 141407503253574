<template>
  <v-card
    dark
    height="140"
    outlined
    color="#555"
    class="expande-horizontal pa-3"
    style="flex-wrap: wrap; border-radius: 12px;"
  >
    <div class="expande-horizontal column" style="flex-wrap: nowrap;">
      <span style="font-size: 8pt;" class="fonte mb-2 grey--text"
        >Estabelecimentos cadastrados</span
      >
      <div class="expande-horizontal">
        <span class="fonte-bold black--text" :style="`font-size: 26px;`">{{ get_establishments.docs.length }} Estabelecimento(s)</span>
        <v-spacer></v-spacer>
        <v-btn @click="$router.push('/estabelecimentos')" icon color="grey">
          <v-icon size="30" color="grey"> mdi-chevron-right </v-icon>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
    </div>

    <div class="expande-horizontal mt-3">
      <!-- <v-btn v-if="getBalanceUnilevel.extract_locked.length > 0" depressed rounded outlined small light color="#555">
        <v-icon size="19">mdi-cash-lock-open</v-icon>
        <span class="text-capitalize ml-1">Liberação em {{ $moment(getBalanceUnilevel.extract_locked[0].unlock_date).format('DD/MM') }}</span>
      </v-btn> -->
      <!-- <v-btn v-else depressed rounded outlined small light color="#555">
        <v-icon size="19">mdi-currency-usd-off</v-icon>
        <span class="text-capitalize ml-1">Sem valores pendentes</span>
      </v-btn> -->
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["get_establishments"])
  },
  methods: {
    ...mapActions(['listar_establishments'])
  },
  created() {
    this.listar_establishments();
  }
};
</script>
