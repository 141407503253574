<template>
  <v-card
    dark
    height="140"
    class="expande-horizontal pa-3 card-balance-style"
    style="flex-wrap: wrap; border-radius: 12px;"
  >
    <div class="expande-horizontal column" style="flex-wrap: nowrap;">
      <span style="font-size: 8pt;" class="fonte mb-2 white--text"
        >Total Aportado</span
      >
      <div class="expande-horizontal">
        <span class="fonte-bold white--text" :style="`font-size: 26px;`"
          >{{ $helper.formataSaldo(totalPurchase) }}  de 
          {{ $helper.formataSaldo((getLoggedUser.career_info.price || 0) * 20) }}
        </span>
        <v-spacer></v-spacer>
        <v-btn @click="$router.push('/transactions')" icon color="grey">
          <v-icon size="30" color="white"> mdi-chevron-right </v-icon>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
    </div>

    <div class="expande-horizontal mt-3">
      <!-- <v-btn depressed rounded outlined small light color="#555">
        <span class="text-capitalize"> 
          <v-icon size="19">mdi-piggy-bank-outline</v-icon>
          {{ $helper.formataSaldo(getBalanceUnilevel.balance_locked) }}
        </span>
      </v-btn> -->

      <v-btn class="mr-2" @click="openPurchase({ ...getInvestments[0], purchase_type: 'investment' })" color="yellow" small>
        <span class="fonte-bold black--text">Novo aporte</span>
        <span style="font-size: 20pt;"></span>
      </v-btn>
      <v-btn @click="$router.push('/saque/purchase')" color="yellow" small>
        <span class="fonte-bold black--text">Retirada</span>
        <span style="font-size: 20pt;"></span>
      </v-btn>
      <!-- <v-btn class="ml-2" depressed rounded outlined small light color="#555">
        <v-icon size="19">mdi-wallet-plus-outline</v-icon>
        <span class="text-capitalize">Depósito</span>
      </v-btn> -->
      <!-- <v-btn class="ml-2" depressed rounded outlined small light color="#555">
        <v-icon size="19">mdi-wallet-plus-outline</v-icon>
        <span class="text-capitalize">Receber</span>
      </v-btn> -->
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getPurchases", "getLoggedUser", "getInvestments"]),
    totalPurchase() {
      let investments = this.getPurchases.filter(
        i => i.purchase_type == "investment" && i.current_status == "active"
      );
      let total = 0;
      investments.forEach(element => {
        total += parseInt(element.item.price);
      });
      return total;
    }
  },
  methods: {
    ...mapActions(['openPurchase'])
  }
};
</script>
